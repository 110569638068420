<template lang="pug">
div
  fi-data-field(:label = '$t("reportingType")' :label-cols = 3 ) {{ classifierById('TYP_PRTCTN', vehicleData.assetReportingType).human }}
  .flex-row-direction
    .loan-collateral-asset-vehicle-fields-display.animated.fadeIn.width-50.margin-right-20
      fi-data-field(:label = '$t("vehicleBrand")') {{ vehicleData.vehicleBrand }}
      fi-data-field(:label = '$t("vehicleModel")') {{ vehicleData.vehicleModel }}
      fi-data-field(:label = '$t("vehicleProductionYear")') {{ vehicleData.vehicleProductionYear }}
      fi-data-field(:label = '$t("vehiclePower")') {{ vehicleData.vehiclePower }}
      fi-data-field(:label = '$t("vehicleMileage")') {{ vehicleData.vehicleMileage }}
      fi-data-field(:label = '$t("vehicleSerialNumber")') {{ vehicleData.vehicleSerialNumber }}
      fi-data-field(:label = '$t("vehicleRegistrationNumber")') {{ vehicleData.vehicleRegistrationNumber }}
      fi-data-field(:label = '$t("sellerId")') {{ customerName(seller) }}
      fi-data-field(:label = '$t("sellerContactName")') {{ vehicleData.sellerContactName }}
      fi-data-field(:label = '$t("sellerPhone")') {{ vehicleData.sellerPhone }}
      fi-data-field(:label = '$t("sellerEmail")') {{ vehicleData.sellerEmail }}
      fi-data-field(:label = '$t("appraisalReport")') {{ assetData.appraisalReport ? $t('common:yes') : $t('common:no') }}
      template(v-if = 'assetData.appraisalReport')
        fi-data-field(:label = '$t("appraisalPartnerName")') {{ assetData.appraisalPartnerName }}
        fi-data-field(:label = '$t("appraisalValue")') {{ assetData.appraisalValue }}
      fi-data-field(:label = '$t("postalСode")') {{ vehicleData.warrantyPostalCode }}
      fi-data-field(:label = '$t("warrantyOwnerName")')
        .flex-column-direction
          <table v-if = 'vehicleData.assetsOwners' style="width:100%">
            template(v-for = '(owner, index) in vehicleData.assetsOwners')
              <tr>
                <td class = 'bold' style="border:none, padding:0px"> {{ owner.customerName }} </td>
              </tr>
          </table>
      fi-data-field(:label = '$t("assetInfo")')
        .text(v-html = 'sanitizedText')
      fi-data-field(:label = '$t("internalComment")')
        .text(v-html = 'sanitizedCommentText')
    div.width-50
      fi-data-field(:label = '$t("valueType")') {{ classifierById('TYP_PRTCTN_VL', vehicleData.valueType).human }}
      fi-data-field(:label = '$t("valuationType")') {{ classifierById('PRTCTN_VLTN_APPRCH', vehicleData.valuationType).human }}
      fi-data-field(:label = '$t("locationCode")') {{ classifierById('ISO3166_NUTS_DSJNT', vehicleData.collateralLocationCode).human }}
      fi-data-field(:label = '$t("assetStatus")') {{ vehicleData.assetStatus }}
      //- fi-data-field(:label = '$t("appraisalPartner")') {{ customerName(appraisalPartnerData) }}
      fi-data-field(:label = '$t("assetSoldValue")') {{ vehicleData.assetSoldValue }}
      fi-data-field(:label = '$t("soldDate")') {{ $t('common:formatDate', { value: vehicleData.soldDate }) }}
      fi-data-field(:label = '$t("warrantyArea")')
        .row
          .col {{ vehicleData.warrantyArea }}
          .col {{ classifierById('immovableAreaSizeType', vehicleData.areaSizeTypeId).human }}
      fi-data-field(:label = '$t("value")') {{ assetData.value | money }}
      fi-data-field(:label = '$t("valueDate")') {{ $t('common:formatDate', { value: assetData.valueDate }) }}
      fi-data-field(:label = '$t("allocatedValue")') {{ assetData.allocatedValue | money }}
      fi-data-field(:label = '$t("appraisalReport")') {{ assetData.appraisalReport ? $t('common:yes') : $t('common:no') }}
      template(v-if = 'assetData.appraisalReport')
        fi-data-field(:label = '$t("appraisalPartnerName")') {{ assetData.appraisalPartnerName }}
        fi-data-field(:label = '$t("appraisalValue")') {{ assetData.appraisalValue }}
      fi-data-field(:label = '$t("insuranceMandatory")') {{ assetData.insuranceMandatory ? $t('common:yes') : $t('common:no') }}
      fi-data-field(:label = '$t("insuranceCompany")') {{ assetData.insuranceCompany }}
      fi-data-field(:label = '$t("insuredUntil")') {{ $t('common:formatDate', { value: assetData.insuredUntil }) }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapGetters } from 'vuex'
import { customerName } from '@/helpers'
import sanitizeHtml from 'sanitize-html'

export default {
  name: 'loan-collateral-asset-vehicle-fields-display',

  components: { FiDataField },

  props: {
    vehicleData: {
      type: Object,
      required: true
    },
    assetData: {
      type: Object,
      required: true
    },
    seller: {
      type: Object,
      default: null
    },
    appraisalPartnerData: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    sanitizedText () {
      return sanitizeHtml(this.assetData.assetInfo || '', {
        allowedTags: false,
        allowedAttributes: false,
        allowedSchemes: ['data', 'http'],
        nonTextTags: ['style', 'script', 'textarea', 'noscript']
      })
    },
    sanitizedCommentText () {
      return sanitizeHtml(this.assetData.comment || '', {
        allowedTags: false,
        allowedAttributes: false,
        allowedSchemes: ['data', 'http'],
        nonTextTags: ['style', 'script', 'textarea', 'noscript']
      })
    }
  },

  methods: {
    customerName
  }
}
</script>

<style lang="scss" scoped>
  .flex-row-direction {
    display: flex;
  }
  .flex-column-direction {
    display: flex; flex-direction: column;
  }
  .bold {
    font-weight: bold;
    border: none !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .justify-content-space-evenly {
    justify-content: space-evenly;
  }
  .width-50 {
    width: 50%;
  }
  .margin-right-20 {
    margin-right: 20px
  }
  </style>


<i18n>
en:
  vehicleBrand:              "Brand"
  vehicleModel:              "Model"
  vehicleProductionYear:     "Production year"
  vehiclePower:              "Power, kw"
  vehicleMileage:            "Mileage, km"
  vehicleSerialNumber:       "Serial number"
  vehicleRegistrationNumber: "Registration number"
  sellerId:                  "Seller"
  sellerContactName:         "Seller contact name"
  sellerPhone:               "Seller contact phone"
  sellerEmail:               "Seller contact e-mail"
  value:                     "Value"
  valueDate:                 "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:           "Appraisal report"
  appraisalPartnerName:      "Appraisal partner"
  appraisalValue:            "Appraisal value"
  insuranceMandatory:        "Insurance mandatory"
  insuranceCompany:          "Insurance company"
  insuredUntil:              "Insured until"
  assetInfo:                 "Asset info"
  internalComment:           "Internal comments"
  warrantyArea:              "Area"
  postalСode:                      "Postal code"
  soldDate:                        "Sold date"
  assetSoldValue:                  "Asset sold value"
  appraisalPartner:                "Appraisal partner"
  reportingType:                   "Reporting type"
  valueType:                       "Value type"
  valuationType:                   "Valuation type"
  locationCode:                    "Location code"
  assetStatus:                     "Asset status"
  warrantyOwnerName:               "Owner name"
et:
  vehicleBrand:              "Brand"
  vehicleModel:              "Model"
  vehicleProductionYear:     "Production year"
  vehiclePower:              "Power, kw"
  vehicleMileage:            "Mileage, km"
  vehicleSerialNumber:       "Serial number"
  vehicleRegistrationNumber: "Registration number"
  sellerId:                  "Seller"
  sellerContactName:         "Seller contact name"
  sellerPhone:               "Seller contact phone"
  sellerEmail:               "Seller contact e-mail"
  value:                     "Value"
  valueDate:                 "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:           "Appraisal report"
  appraisalPartnerName:      "Appraisal partner"
  appraisalValue:            "Appraisal value"
  insuranceMandatory:        "Insurance mandatory"
  insuranceCompany:          "Insurance company"
  insuredUntil:              "Insured until"
  assetInfo:                 "Asset info"
  internalComment:           "Internal comments"
  warrantyArea:              "Area"
  postalСode:                      "Postal code"
  soldDate:                        "Sold date"
  assetSoldValue:                  "Asset sold value"
  appraisalPartner:                "Appraisal partner"
  reportingType:                   "Reporting type"
  valueType:                       "Value type"
  valuationType:                   "Valuation type"
  locationCode:                    "Location code"
  assetStatus:                     "Asset status"
  warrantyOwnerName:               "Owner name"
ru:
  vehicleBrand:              "Brand"
  vehicleModel:              "Model"
  vehicleProductionYear:     "Production year"
  vehiclePower:              "Power, kw"
  vehicleMileage:            "Mileage, km"
  vehicleSerialNumber:       "Serial number"
  vehicleRegistrationNumber: "Registration number"
  sellerId:                  "Seller"
  sellerContactName:         "Seller contact name"
  sellerPhone:               "Seller contact phone"
  sellerEmail:               "Seller contact e-mail"
  value:                     "Value"
  valueDate:                 "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:           "Appraisal report"
  appraisalPartnerName:      "Appraisal partner"
  appraisalValue:            "Appraisal value"
  insuranceMandatory:        "Insurance mandatory"
  insuranceCompany:          "Insurance company"
  insuredUntil:              "Insured until"
  assetInfo:                 "Asset info"
  internalComment:           "Internal comments"
  warrantyArea:              "Area"
  postalСode:                      "Postal code"
  soldDate:                        "Sold date"
  assetSoldValue:                  "Asset sold value"
  appraisalPartner:                "Appraisal partner"
  reportingType:                   "Reporting type"
  valueType:                       "Value type"
  valuationType:                   "Valuation type"
  locationCode:                    "Location code"
  assetStatus:                     "Asset status"
  warrantyOwnerName:               "Owner name"
</i18n>
